<template>
    <div :class="{ 'active-point box-border-bottom': state.active }" :style="item.quantity > item.instock ? 'border: solid 1px red;' : ''">
        <div class="flex justify-between items-center mt2 pa2">
            <div @click="state.active = !state.active" class="flex items-center" style="cursor:pointer;">
                <div class="flex items-centers w-30">
                    <img v-if="state.active" src="../assets/images/arrow-down.svg" alt="" />
                    <img v-else src="../assets/images/arrow-right.svg" alt="" />
                    <div class="pl2 itemMeasurement">{{ item.quantity }}  {{item.measurement}}</div>
                </div>
                <div class="pl2">
                    <div class="font-w1">{{ titleCase(item.name) }}</div>
                    <div>
                        <span v-if="item?.discount > 0 && item?.discount !== ''">
                            <span style="text-decoration:line-through">{{ formatAmount(item?.quantity * item?.oldPrice, $store.state.Settings?.currency) }}</span>{{ ' ' }}
                        </span>
                        <span>{{
                            formatAmount(item?.total, $store.state.Settings?.currency)
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="flex items-center" style="color: #132C8C;">
                <div>{{ formatAmount(item.total, $store.state.Settings?.currency) }}</div>
                <a href="#" @click.prevent="onRemoveItem(index)" class="posDelete">
                    <img src="../assets/images/trash.png" class="ph2 itemDel" alt="" />
                </a>
            </div>
        </div>
        <div v-if="item.quantity > item.instock" class="text-danger ph4 small">Insufficient item in stock</div>
        <div v-if="state.active">
            <div class="flex flex-wrap justify-between pa3 pb2">
                <div class="flex flex-column w-30-l w-100 mb3 mb0-l addMeasurement">
                    <label class="pb2" for="">Quantity</label>
                    <input type="number" min="1" class="box-border pa2" v-model="item.quantity" @input="onItemChange(index)" /><span class="measureUnit">{{item.measurement}}</span>
                </div>
                <div class="flex flex-column w-30-l w-50 pr1 pr0-l ">
                    <label class="pb2" for="">Price</label>
                    <input 
                        type="text" 
                        class="box-border pa2" 
                        v-model="item.price" 
                        @input="onItemPriceChange(index)" 
                    />
                </div>
                <div class="flex flex-column w-30-l w-50 pl1 pl0-l ">
                    <label class="pb2" for="">{{item.discount < 0 ? 'Markup (%)' : 'Discount %'}}</label>
                    <input
                        type="number"
                        min="0"
                        max="100"
                        class="box-border pa2"
                        v-model="item.discount"
                        @input="onItemDiscountChange(index)"
                    />
                </div>
            </div>
            <div class="flex justify-between ph3 pb2">
                <div class="flex flex-column w-100">
                    <label for="item-notes" class="pb2">
                        <span style="cursor:pointer;color:#132c8c;" @click.prevent="state.noteStatus = !state.noteStatus"
                            >Notes</span
                        >
                    </label>
                    <textarea
                        v-if="state.noteStatus"
                        id="item-notes"
                        class="box-border pa2 w-100"
                        placeholder="Enter notes for this item"
                        rows="1"
                        maxlength="50"
                        v-model="item.note"
                    ></textarea>
                </div>
            </div>
            <!-- <div class="flex justify-between pr3 pb3">
                <div></div>
                <div style="color: #132C8C;">
                    <img src="imgs/info.svg" alt="">
                    Show Inventory & Details 
                </div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'
import { formatAmount, formatQuantity, formatDateFilter, getCurrencyName, titleCase } from '@/utils/lib'

export default {
    name: 'PosItem',
    props: {
        item: Object,
        onItemChange: Function,
        onItemPriceChange: Function,
        onItemDiscountChange: Function,
        onRemoveItem: Function
    },

    setup() {
        const state = reactive({ active: false, noteStatus: false })
        return {
            state,
            formatAmount,
            formatQuantity,
            formatDateFilter,
            getCurrencyName,
            titleCase
        }
    }
}
</script>
<style>
.addMeasurement {
    position: relative;
}
.measureUnit {
    position: absolute;
    top: 70%;
    right: 10px;
    transform: translateY(-50%);
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.itemMeasurement{
    width: 200px;
}
.posDelete{
    width: 30px;
}

</style>