<template>
    <!-- form modal -->

    <div
        v-if="showRetrieveSaleModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showRetrieveSaleModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="retrieveSaleRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Parked Sales</div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <div v-if="parkedSales" class="pa3 pt0" style="height: 80vh; overflow:scroll;">
                <div v-for="(sale, index) in parkedSales" :key="index"
                    class="flex flex-wrap items-center justify-between items-center pv3 pr2 box-border-bottom tl"
                >
                    <a href="" @click.prevent="retrieveSale(sale.reference)">
                        <div v-if="sale?.items?.length > 1" class="truncate">
                            <!-- {{ sale?.items?.[0]?.name }} +{{ sale?.items.length - 1 }} more item(s) -->
                            {{ Array.prototype.map.call(sale?.items, function(item) { return item.name; }).join(", ") }}
                        </div>
                        <div v-else-if="sale?.items?.[0]?.name">{{ sale?.items?.[0]?.name }}</div>
                        <div>Parked by {{ sale.staffName }} on {{ formatDateFilter(sale.createdAt) }}</div>
                        <div v-if="sale.customer && sale.customer?.name">Customer: {{ sale.customer.name }}</div>
                    </a>
                    <span class="b">{{ sale?.number || sale.reference }}</span>
                </div>
            </div>
            <div v-else class="pa4 tc text-muted lighter">
                <div>
                    <font-awesome-icon :icon="'fa-solid fa-folder-open'" class="fa-5x" />
                </div>
                <div class="f3">No parked sales</div>
            </div>

        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { formatDateFilter } from '@/utils/lib'

export default {
    name: 'RetrieveSaleModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const retrieveSaleRef = ref(null)
        const store = useStore()
        const showRetrieveSaleModal = computed(() => store.state?.Pos?.showRetrieveSaleModal)
        const parkedSales = computed(() => store.state?.Pos?.parkedSales)

        const initialState = {
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeRetrieveSaleModal = () => {
            props.closeModal()
        }

        const retrieveSale = (reference) => {
            state.disableSubmitButton = true

            store.dispatch('Pos/retrieveSale', reference).then((data) => {
                state.disableSubmitButton = false
                emit("onRetrieveSales", data)
                closeRetrieveSaleModal()
            }).catch((error) => {
                state.disableSubmitButton = false
                console.error(error)
            })
        }

        const handleOnClickOutsideModal = (e) => {
            if (retrieveSaleRef?.value && !retrieveSaleRef?.value.contains(e.target)) {
                closeRetrieveSaleModal()
            }
        }

        onBeforeMount(() => {
            store.dispatch('Pos/getParkedSales')
        })

        return {
            state,
            parkedSales,
            retrieveSale,
            showRetrieveSaleModal,
            retrieveSaleRef,
            handleOnClickOutsideModal,
            formatDateFilter
        }
    },
}
</script>

<style>
    .entity-form input[type='radio'] {
        background: unset;
        border: none;
        box-shadow: none;
        padding: unset;
        width: unset;
    }
</style>
