<template>
    <div class="box-border mt4 w-80-l w-90 center mt4 mini-spacing">
        <div class="w-100 flex justify-between">
            <div class="box-border-bottom b f4-l f5 pa3-l pa3" style="color: #132c8c">Transaction</div>
            <div class="box-border-bottom b f4-l f5 pa3-l pa3">Reference: {{ data?.reference }}</div>
        </div>
        <div class="pa4-l pa2" style="background: #fbfcfe">
            <div class="tc pa4" style="background: white">
                <div class="flex justify-center items-center" v-if="orgData?.org">
                    <div
                        class="flex justify-center items-center font-w3"
                        style="
                            background: rgb(215, 222, 250);
                            height: 50px;
                            width: 50px;
                            border-radius: 50%;
                            font-size: 25px;
                            color: rgb(19, 44, 140);
                        "
                    >
                        <div ref="companyLogoWrapper" v-if="orgImage">
                            <img
                                ref="companyLogo"
                                :src="orgImage"
                                alt=""
                                style="object-position: center; object-fit: cover; width: 50px; height: 50px"
                                class="rounded-circle"
                            />
                        </div>
                        <span v-else class="org-initials">{{ orgInitials || userInitials }}</span>
                    </div>
                </div>
                <div class="b pv3">{{ orgData?.org }}</div>
                <div class="pv1" v-if="orgData?.phone">{{ orgData?.phone }}</div>
                <div class="pv1" v-if="orgData?.email">{{ orgData?.email }}</div>
                <div class="pv1" v-if="orgData?.address">{{ orgData?.address }}</div>

                <div class="pt3">
                    <img :src="require('@/assets/images/Succes-Icons.svg')" alt="" />
                    <div class="pv1">Transaction Total</div>
                    <div class="pv2 f4 b">{{ data.amount }}</div>
                </div>
                <div class="font-w1 f5 pt3">POS Receipt</div>
                <div class="pv1 mt2">{{ new Date(data.dateIssued).toUTCString() }}</div>
            </div>
            <div style="background: white">
                <div class="items-warapper pa5-l pa4 font-w1">
                    <!-- Products -->
                    <div v-for="item of data?.items" v-bind:key="item.id">
                        <div class="flex justify-between pv3 box-border-bottom ttc" style="border-top: 1px solid #e3e8ee">
                            <div>
                                <div>{{ titleCase(item.name) }} ({{ item.quantity }})</div>
                                <small v-if="item?.note">{{ item.note }}</small>
                            </div>
                            <div>
                                <span class="pl2" v-if="item?.discount > 0 && item?.discount !== ''">
                                    <span style="text-decoration: line-through">{{
                                        formatAmount(item?.quantity * item?.oldPrice, $store.state.Settings?.currency)
                                    }}</span>
                                    {{ ' ' }}
                                </span>
                                <span>{{ formatAmount(item?.total, $store.state.Settings?.currency) }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex justify-between pv3 box-border-bottom">
                        <div>Subtotal</div>
                        <div>{{ formatAmount(data?.subtotal, $store.state.Settings?.currency) }}</div>
                    </div>

                    <div
                        v-if="data?.discount !== 0 && data?.discount !== ''"
                        class="flex justify-between pv3 box-border-bottom"
                    >
                        <div>
                            Discount <span v-if="data?.discountType === 'percent'">({{ data?.discount }}%)</span>
                        </div>
                        <div v-if="data?.discountType === 'percent'">
                            ({{ formatAmount((data?.discount / 100) * data?.subtotal, $store.state.Settings?.currency) }})
                        </div>
                        <div v-else>({{ formatAmount(data?.discount, $store.state.Settings?.currency) }})</div>
                    </div>

                    <div
                        v-if="data?.tax !== 0 && data?.tax !== ''"
                        class="flex justify-between pv3 box-border-bottom"
                        style="color: #999999"
                    >
                        <div>
                            Tax <span>({{ data?.tax }}%)</span>
                        </div>
                        <div v-if="data?.discountType === 'percent'">
                            {{
                                formatAmount(
                                    (data?.tax / 100) * (data?.subtotal - (data?.discount / 100) * data?.subtotal),
                                    $store.state.Settings?.currency
                                )
                            }}
                        </div>
                        <div v-else>
                            {{
                                formatAmount(
                                    (data?.tax / 100) * (data?.subtotal - data?.discount),
                                    $store.state.Settings?.currency
                                )
                            }}
                        </div>
                    </div>

                    <div class="flex justify-between pv3 box-border-bottom f5 b">
                        <div>Grand Total</div>
                        <div>{{ formatAmount(data.totalAmount, $store.state.Settings?.currency) }}</div>
                    </div>
                    
                    <div class="flex justify-between pv3 box-border-bottom f5">
                        <div class="dark-green">Amount Paid</div>
                        <div class="dark-green">{{ formatAmount(data?.totalAmount, $store.state.Settings?.currency) }}</div>
                    </div>

                    <div v-if="data?.totalMoneySaved" class="flex justify-between pv3 box-border-bottom f5">
                        <div class="dark-green">Total Money Saved</div>
                        <div class="dark-green">{{ formatAmount(data.totalMoneySaved, $store.state.Settings?.currency) }}</div>
                    </div>

                    <div v-if="data?.customer?.name" class="flex items-center justify-between pv3 box-border-bottom">
                        <div>Billed to</div>
                        <div class="w-50-l w-70 tr">
                            <div class="font-w2 pb1">
                                {{ `${data?.customer?.name} ${data?.customer?.company ? `(${data?.customer?.company})` : ''}` }}
                            </div>
                            <div class="lh-copy">
                                {{ data?.customer?.address || '' }}
                            </div>
                            <div>{{ data?.customer?.phone || '' }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="data?.notes !== ''" class="flex justify-between pv3 box-border-bottom">
                    <div><strong>Note: </strong><br />{{ data?.notes }}</div>
                </div>
                <div class="flex items-center pv2 pt2">
                    <span class="mr1 b">PAYMENT INFO </span>
                </div>
                <!-- column table -->
                <div v-for="account of data.glAccounts" :key="account" class="flex flex-column pa2-l pa2">
                    <div class="flex justify-between pv2 box-border-bottom">
                        <div>{{ account.glAccount }}</div>
                        <div>{{ formatAmount(account?.glAmount, $store.state.Settings?.currency) }}</div>
                    </div>
                </div>
                <div v-if="amountRemaining < 0" class="flex flex-column pa2-l pa2">
                    <div class="flex justify-between pv2 box-border-bottom">
                        <div>Customer Change</div>
                        <div class="text-danger">
                            {{ formatAmount(amountCollected - amount, $store.state.Settings?.currency) }}
                        </div>
                    </div>
                </div>

                <div class="flex items-center justify-center pv3 box-border-bottom">
                    <span class="mr1">Staff: </span>
                    <span class="ttc">{{ orgData?.fullName }}</span>
                </div>
                <div v-if="state?.footnote" v-html="state.footnote" class="footnote ph4-l tc"></div>
                <div class="flex items-center justify-center w-50-l w-80 center pb2 pt2">
                    <div class="b pr2" style="color: #132c8c">Generated from:</div>
                    <a href="https://simplebks.com" target="_blank"
                        ><img :src="require('@/assets/images/simple-logo-mobile.svg')" alt=""
                    /></a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import { formatAmount, formatDateFilter, titleCase } from '@/utils/lib'
export default {
    name: 'PosReceipt',
    props: {
        data: Object,
    },

    setup(props) {
        const store = useStore()
        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const amount = computed(() => store.state?.Pos?.amount)
        const amountCollected = computed(() => store.state?.Pos?.amountCollected)
        const amountRemaining = computed(() => store.state?.Pos?.amountRemaining)

        const state = reactive({
            footnote: '',
        })

        onMounted(() => {
            store.dispatch('Settings/getAccountSettings').then((data) => {
                if (data.status) {
                    state.footnote = data?.data?.footnote || ''
                }
            })
        })

        return {
            state,
            orgData,
            orgImage,
            orgInitials,
            amount,
            amountCollected,
            amountRemaining,
            formatAmount,
            formatDateFilter,
            titleCase,
        }
    },
}
</script>
