<template>
    <div>
        <NewBanner class="stickyNew" />
        <app-wrapper-alt>
            <template v-slot:child>
                <RetrieveSaleModal :closeModal="() => toggleRetrieveSalesModal(false)" @onRetrieveSales="onRetrieveSales" />
                <PosPayModal
                    :closeModal="() => togglePaySalesModal(false)"
                    @onPayment="updateGlAccounts"
                    @onCompleteSale="completeSale"
                    @onViewReceipt="viewReceipt"
                />
                <CreateProduct
                    :closeModal="() => toggleCreateProduct(false)"
                    :transactionCategory="'PRODUCT'"
                    @on-created="onProductCreated"
                />

                <ProductVariantsModal
                    :closeModal="() => toggleProductVariantsModal({ status: false, index: null })"
                    @on-variant-selected="addPosItem"
                />
                <access-denied v-cloak v-if="!canAccessPos" :title="'Point of Sale'" class="v-cloak--hidden" />
                <div v-else v-cloak class="v-cloak--hidden">
                    <!--point of sale-->
                    <div class="w-90 center">
                        <div class="flex flex-wrap items-center justify-between items-center pb2 pv4 gap8">
                            <div>
                                <div class="pb3 pb0-l f3 b">Point of Sale</div>
                            </div>
                            <div class="flex scrollmenu">
                                <button
                                    class="btn2"
                                    @click="createSale('parked')"
                                    :disabled="
                                        state.isProcessing ||
                                        state.pos.items.length == 0 ||
                                        (state.pos.items.length >= 1 && state.pos.items[0].id == '')
                                    "
                                >
                                    Park sale
                                </button>
                                <button
                                    class="btn2 ml3"
                                    @click="toggleRetrieveSalesModal(true)"
                                    :disabled="!state.canRetrieveSale || !parkedSales.length"
                                >
                                    Retrieve sale
                                </button>
                                <!-- <button class=" ml3" :disabled="state.isProcessing">
                                More Actions
                            </button> -->
                            </div>
                        </div>
                    </div>
                    <!--point of sale-->

                    <!-- search and add customer -->
                    <div class="box-border w-90 center">
                        <div class="pa2 flex flex-wrap">
                            <div class="w-50-l pr1-l pr0 w-100">
                                <div class="searchContainer2 box-border">
                                    <img src="../../assets/images/search.svg" alt="" />
                                    <input
                                        class="searchBox pl2 w-100"
                                        type="text"
                                        name="search"
                                        v-model="state.productName"
                                        placeholder="Search for products with name, SKU or scan barcode"
                                        @input="(element) => handleProductSearchOnChange(element.target.value)"
                                        @keydown.enter="addFirstItem"
                                        autofocus
                                    />
                                    <a
                                        v-if="role === 'owner' || role === 'admin' || rolePermissions?.includes(32)"
                                        href=""
                                        @click.prevent="() => toggleCreateProduct(true, state.productName)"
                                        title="Create new product"
                                    >
                                        <img src="../../assets/images/circle-plus-solid.svg" alt="" style="width: 18px" />
                                    </a>
                                </div>
                                <div
                                    v-if="productItems.length"
                                    class="flex flex-wrap justify-between pt2"
                                    style="height: 550px; overflow-y: scroll; overflow-x: hidden"
                                >
                                    <div class="pr1 w-33-l w-50 mb1" v-for="(product, index) in productItems" :key="index">
                                        <a
                                            href="#"
                                            @click.prevent="addPosItem(product)"
                                            :ref="
                                                (el) => {
                                                    posItemsRef[index] = el
                                                }
                                            "
                                        >
                                            <div class="box-border pa2">
                                                <img
                                                    :src="product?.imageUrl || require('@/assets/images/inventory-default.png')"
                                                    alt="product"
                                                    style="width: 50%"
                                                />
                                                <div
                                                    class="text-truncate pv2 font-w1"
                                                    style="color: #132c8c"
                                                    :title="product.name"
                                                >
                                                    <div>{{ titleCase(product.name) }}</div>
                                                    <div v-if="product?.hasVariants" class="text-muted text-small">
                                                        {{ product.variantsCount }} variants
                                                    </div>
                                                </div>
                                                <div v-if="!product?.hasVariants" class="pt2x">
                                                    {{ formatAmount(product?.sellingPrice, $store.state.Settings?.currency) }}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div v-else class="pt2">
                                    <TableEmptyState
                                        :data-array="salesHistory"
                                        message="No product in your inventory"
                                        rout-name="Products"
                                        route-text="Go to inventory"
                                    />
                                </div>
                            </div>
                            <div class="w-50-l pl1-l pl0 w-100">
                                <pos-open-float-form
                                    v-if="!activeRegister || activeRegister?.closingTime"
                                    width="w-100-l w-90"
                                    :show-message="true"
                                    :redirect-to-sales="false"
                                />
                                <div v-else>
                                    <pos-customer @on-update-pos-data="updatePosData" />
                                    <div id="post-items" style="height: 350px; overflow-y: scroll; overflow-x: hidden">
                                        <div v-for="(item, index) in posItems" class="box-border-bottom" :key="index">
                                            <pos-item
                                                :item="item"
                                                :on-item-change="() => onItemChange(index)"
                                                :on-item-price-change="() => onItemPriceChange(index)"
                                                :on-item-discount-change="() => onItemDiscountChange(index)"
                                                :on-remove-item="() => removePosItem(index)"
                                            />
                                        </div>
                                    </div>
                                    <!-- <div style="height: 200px; background: #F6F8FE;"></div> -->
                                    <div class="flex justify-between box-border-bottom pv3">
                                        <div class="b">ADD</div>
                                        <div class="flex flex-wrap unZIndexx" style="color: 55596E; opacity: 0.75">
                                            <div v-if="!state.showDiscount" class="pr2">
                                                <a href="" @click.prevent="state.showDiscount = !state.showDiscount">Discount</a>
                                            </div>
                                            <div v-if="!state.showTax" class="pr2">
                                                <a href="" @click.prevent="state.showTax = !state.showTax">Tax</a>
                                            </div>
                                            <div v-if="!state.showNote">
                                                <a href="" @click.prevent="state.showNote = !state.showNote">Note</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex justify-between box-border-bottom pv3">
                                        <div class="b">SUBTOTAL</div>
                                        <div>{{ formatAmount(subtotal, $store.state.Settings?.currency) }}</div>
                                    </div>
                                    <div v-if="state.showDiscount" class="flex justify-between box-border-bottom pv3 unZIndex">
                                        <a href="" @click.prevent="state.showDiscount = !state.showDiscount">
                                            <img
                                                src="https://img.icons8.com/dotty/22/000000/delete-sign.png"
                                                alt="delete icon"
                                                style="width: 60%"
                                            />
                                        </a>
                                        <div class="b">DISCOUNT</div>
                                        <div>
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                class="box-border pa2"
                                                v-model="discount"
                                                @input="(element) => onDiscountChange(element.target.value)"
                                            />
                                            <select
                                                v-model="discountType"
                                                @change="(element) => onDiscountTypeChange(element.target.value)"
                                            >
                                                <option value="percent">%</option>
                                                <option value="fixed">N</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div v-if="state.showTax" class="flex justify-between box-border-bottom pv3">
                                        <a href="" @click.prevent="state.showTax = !state.showTax">
                                            <img
                                                src="https://img.icons8.com/dotty/22/000000/delete-sign.png"
                                                alt="delete icon"
                                                style="width: 60%"
                                            />
                                        </a>
                                        <div class="b">TAX</div>
                                        <div>
                                            <input
                                                type="number"
                                                min="0"
                                                max="100"
                                                class="box-border pa2"
                                                v-model="tax"
                                                @input="(element) => onTaxChange(element.target.value)"
                                            />
                                            %
                                        </div>
                                    </div>
                                    <div v-if="state.showNote" class="flex justify-between box-border-bottom pv3">
                                        <a href="" @click.prevent="state.showNote = !state.showNote">
                                            <img
                                                src="https://img.icons8.com/dotty/22/000000/delete-sign.png"
                                                alt="delete icon"
                                                style="width: 60%"
                                            />
                                        </a>
                                        <div class="b">Note</div>
                                        <div>
                                            <textarea class="box-border" placeholder="Note" v-model="state.pos.notes"></textarea>
                                        </div>
                                    </div>

                                    <div class="flex pv3">
                                        <div class="w-100">
                                            <label class="b">TAGS</label>
                                            <vue-tags-input
                                                class="mt2 w-100"
                                                v-model="state.tag"
                                                :tags="state.pos.tags"
                                                :validation="state.validation"
                                                :autocomplete-items="state.myTags"
                                                :max-tags="5"
                                                placeholder="Add or select tag"
                                                @tags-changed="(newTags) => (state.pos.tags = newTags)"
                                            />
                                        </div>
                                    </div>

                                    <button
                                        class="w-100 flex justify-between mt4"
                                        @click="togglePaySalesModal(true)"
                                        :disabled="posItems?.length == 0 || (posItems?.length >= 1 && posItems?.[0].id == '')"
                                    >
                                        <div>Pay {{ totalItems }} items</div>
                                        <div>{{ formatAmount(amount, $store.state.Settings?.currency) }}</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="receiptContainer" v-show="false">
                        <pos-receipt :data="state.pos" />
                    </div>
                </div>
            </template>
        </app-wrapper-alt>
    </div>
</template>

<script>
import { computed, onBeforeUpdate, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import ShortUniqueId from 'short-unique-id'
import moment from 'moment-timezone'
import { formatAmount, titleCase, calculateTotalMoneySaved } from '@/utils/lib'
import AppWrapperAlt from '@/layout/AppWrapperAlt'
import PosItem from '@/components/PosItem.vue'
import PosCustomer from '@/components/PosCustomer.vue'
import RetrieveSaleModal from '@/components/RetrieveSaleModal'
import PosPayModal from '@/components/PosPayModal'
import CreateProduct from '@/components/CreateProduct'
import ProductVariantsModal from '@/components/ProductVariantsModal'
import PosReceipt from '@/components/PosReceipt'
import PosOpenFloatForm from '@/components/PosOpenFloatForm'
import NewBanner from '@/components/NewBanner'
import AccessDenied from '@/components/AccessDenied'
import TableEmptyState from '@/components/TableEmptyState'
import VueTagsInput from '@sipec/vue3-tags-input'

export default {
    name: 'PosNew',
    components: {
        AppWrapperAlt,
        PosItem,
        PosCustomer,
        RetrieveSaleModal,
        PosPayModal,
        CreateProduct,
        PosReceipt,
        PosOpenFloatForm,
        AccessDenied,
        TableEmptyState,
        ProductVariantsModal,
        VueTagsInput,
        NewBanner,
    },

    setup(props, { emit }) {
        const store = useStore()
        const router = useRouter()
        const route = useRoute()
        const userId = computed(() => store?.state?.Auth?.userData?.userId)
        const posItemsRef = ref([])
        const productItems = computed(() => store.state?.Pos?.productItems)
        const posItems = computed(() => store.state?.Pos?.items)
        const customer = computed(() => store.state?.Pos?.customer)
        const totalItems = computed(() => store.state?.Pos?.totalItems)
        const subtotal = computed(() => store.state?.Pos?.subtotal)
        const tax = computed(() => store.state?.Pos?.tax)
        const discount = computed(() => store.state?.Pos?.discount)
        const discountType = computed(() => store.state?.Pos?.discountType)
        const amount = computed(() => store.state?.Pos?.amount)
        const amountCollected = computed(() => store.state?.Pos?.amountCollected)
        const glAccounts = computed(() => store.state?.Pos?.glAccounts)
        const activeRegister = computed(() => store.state?.Pos?.activeRegister)
        const canAccessPos = computed(() => store.state?.Pos?.canAccessPos)
        const parkedSales = computed(() => store.state?.Pos?.parkedSales)
        const accountTaxDefault = computed(() => store.state?.Settings?.accountTaxDefault)
        const rolePermissions = computed(() => store?.state?.Auth?.rolePermissions)
        const role = computed(() => store?.state?.Auth?.role)
        const allowZeroStockLevel = computed(() => store.state?.Settings?.allowZeroStockLevel)

        const initialState = () => ({
            pos: {
                id: null,
                register: '',
                reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                dateIssued: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
                status: '',
                customer: {
                    id: '',
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: '',
                    vat_number: '',
                    tax_number: '',
                    website: '',
                },
                items: [
                    {
                        name: '',
                        product: '',
                        price: 0,
                        quantity: 1,
                        instock: 0,
                        sku: '',
                        id: '',
                        type: '',
                    },
                ],
                notes: '',
                subtotal: 0,
                discount: 0,
                discountType: 'percent',
                tax: 0,
                totalAmount: 0,
                totalMoneySaved: 0,
                totalAfterDiscount: 0,
                currency: 'NGN',
                glAccounts: [],
                tags: [],
            },
            entity: '',
            entityName: '',
            entityDropDown: false,
            isRelative: false,
            entityId: '',
            isProcessing: false,
            isValidPosData: false,
            canRetrieveSale: true,
            showDiscount: false,
            showTax: false,
            showNote: false,
            productName: '',
            tag: '',
            myTags: [],
            validation: [
                {
                    classes: 'min-length',
                    rule: (tag) => tag.text.length < 3,
                },
                {
                    classes: 'no-numbers',
                    rule: /^([^0-9]*)$/,
                },
                {
                    classes: 'avoid-item',
                    rule: /^(?!Cannot).*$/,
                    disableAdd: true,
                },
                {
                    classes: 'no-braces',
                    rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
                },
            ],
        })

        let state = reactive(initialState())

        onBeforeUpdate(() => {
            posItemsRef.value = []
        })

        const updatePosData = () => {
            state.pos.register = activeRegister.value?._id
            state.pos.items = posItems.value
            state.pos.customer = customer?.value
            state.pos.subtotal = subtotal.value
            state.pos.totalAmount = amount.value
            state.pos.discount = discount.value
            state.pos.discountType = discountType.value
            state.pos.tax = tax.value
            state.pos.glAccounts = glAccounts.value

            // console.log("hereeeeeeeee", state.pos.customer)

            if (state.pos.customer.id !== '' || (state.pos.items.length >= 1 && state.pos.items[0].id !== '')) {
                state.canRetrieveSale = false
            } else {
                state.canRetrieveSale = true
            }

            localStorage.setItem(`pos_item_${userId?.value}`, JSON.stringify(state.pos.items))
            localStorage.setItem(`pos_customer_${userId?.value}`, JSON.stringify(state.pos.customer))
            localStorage.setItem(`pos_discount_${userId?.value}`, state.pos.discount)
            localStorage.setItem(`pos_discount_type_${userId?.value}`, state.pos.discountType)
            localStorage.setItem(`pos_tax_${userId?.value}`, state.pos.tax)
        }

        const setEntityInitials = (name) => {
            const payload = { entityDetails: { fullName: name } }
            store.dispatch('Entity/setEntityInitials', payload)
        }

        const toggleProductVariantsModal = (payload) => {
            store.dispatch('Inventory/toggleProductVariantsModal', payload)
        }

        const addPosItem = (item) => {
            if (item.hasVariants) {
                store.dispatch('Inventory/getProductVariants', item._id)
                store.dispatch('Inventory/toggleProductVariantsModal', { status: true, index: null })
                return
            }
            store.dispatch('Pos/addPosItem', item).finally(() => {
                const div = document.getElementById('post-items')
                div.scrollTo({
                    top: div.scrollHeight,
                    behavior: 'smooth',
                })
            })
            updatePosData()
            state.productName = ''
        }

        const onItemChange = (index) => {
            store.dispatch('Pos/itemChange', { index })
            updatePosData()
        }

        const onItemDiscountChange = (index) => {
            store.dispatch('Pos/itemChange', { index, field: 'discount' })
            updatePosData()
        }

        const onItemPriceChange = (index) => {
            store.dispatch('Pos/itemChange', { index, field: 'price' })
            updatePosData()
        }

        const onTaxChange = (value) => {
            store.dispatch('Pos/taxChange', value)
            updatePosData()
        }

        const onDiscountChange = (value) => {
            store.dispatch('Pos/discountChange', value)
            updatePosData()
        }

        const onDiscountTypeChange = (value) => {
            store.dispatch('Pos/discountTypeChange', value)
            updatePosData()
        }

        const removePosItem = (index) => {
            store.dispatch('Pos/removePosItem', index)
            updatePosData()
        }

        let timeOut = null
        const handleProductSearchOnChange = (query) => {
            state.productName = query
            clearTimeout(timeOut)
            timeOut = setTimeout(() => {
                store.dispatch('Pos/getProductsEntryBySearch', { query, type: 'PRODUCT' })
            }, 300)
        }

        const addFirstItem = () => {
            timeOut = setTimeout(() => {
                posItemsRef.value[0].click()
                state.productName = ''
            }, 1000)
        }

        const onProductCreated = (data) => {
            console.log('product even received: ', data)
            store.dispatch('Pos/updateProducts', data)
            addPosItem(data)
        }

        const isCustomerAdded = () => {
            if (state.pos.customer.id == '') {
                store.dispatch('Alert/setAlert', {
                    message: `Please add a customer`,
                    status: false,
                })

                state.isValidPosData = false
                return false
            }

            return true
        }

        const isItemAdded = () => {
            if (state.pos.items.length == 0 || (state.pos.items.length >= 1 && state.pos.items[0].id == '')) {
                store.dispatch('Alert/setAlert', {
                    message: `Please add at least one item`,
                    status: false,
                })

                state.isValidPosData = false
                return false
            }

            return true
        }

        const updateGlAccounts = async (data) => {
            await store.dispatch('Pos/updateGlAccounts', data)
            updatePosData()
        }

        const processGlAccounts = (data) => {
            const customerChange = parseFloat(amountCollected.value) - parseFloat(amount.value)
            if (customerChange > 0) {
                const cashIndex = data.findIndex(
                    (item) => item.glAccount === 'Cash' && parseFloat(item.glAmount) > parseFloat(customerChange)
                )

                if (cashIndex >= 0) {
                    data[cashIndex].glAmount = parseFloat(data[cashIndex].glAmount) - parseFloat(customerChange)
                } else {
                    const bankIndex = data.findIndex(
                        (item) => item.glAccount !== 'Cash' && parseFloat(item.glAmount) > parseFloat(customerChange)
                    )

                    if (bankIndex >= 0) {
                        data[bankIndex].glAmount = parseFloat(data[bankIndex].glAmount) - parseFloat(customerChange)
                    }
                }
            }

            return data
        }

        const getTotalAfterDiscount = () => {
            const subtotal = parseFloat(state.pos.subtotal)
            const discount = parseFloat(state.pos.discount)
            if (state.pos.discountType === 'fixed') {
                return subtotal - discount
            }

            return subtotal - (discount / 100) * subtotal
        }

        const createSale = (status) => {
            state.isValidPosData = true
            if (!isItemAdded()) return
            // if (!isCustomerAdded()) return
            // TODO: checkPaymentPlan() on the frontend
            // if(!checkPaymentPlan()) return

            if (!state.isValidPosData) return
            state.pos.register = activeRegister.value?._id
            state.pos.status = status
            state.pos.totalAfterDiscount = getTotalAfterDiscount()
            state.pos.tags = state.pos.tags.map((tag) => {
                return tag.text
            })
            state.isProcessing = true
            const data = state.pos
            data.glAccounts = processGlAccounts(data.glAccounts)

            store
                .dispatch('Pos/createSaleNew', data)
                .then((data) => {
                    if (data?.success) {
                        state.pos = initialState().pos
                        state.entity = ''
                        state.entityName = ''
                        state.entityDropDown = false
                        state.isRelative = false
                        state.entityId = ''
                        state.isProcessing = false
                        state.isValidPosData = false
                        state.canRetrieveSale = true
                        state.showDiscount = false
                        state.showTax = false
                        state.showNote = false
                        store.dispatch('Pos/showPaySalesModal', false)
                        store.dispatch('Pos/getParkedSales')

                        localStorage.removeItem(`pos_item_${userId?.value}`)
                        localStorage.removeItem(`pos_customer_${userId?.value}`)
                        localStorage.removeItem(`pos_discount_${userId?.value}`)
                        localStorage.removeItem(`pos_discount_type_${userId?.value}`)
                        localStorage.removeItem(`pos_tax_${userId?.value}`)
                    }
                })
                .catch((error) => {
                    state.isProcessing = false
                    state.isValidPosData = false
                    // console.error(error)
                })
        }

        const toggleRetrieveSalesModal = (status) => {
            store.dispatch('Pos/showRetrieveSalesModal', status)
        }

        const validateItems = () => {
            let isAnyProductInvetoryZero = false
            for (const product of state.pos.items) {
                if (product.quantity > product.instock) {
                    isAnyProductInvetoryZero = true
                    break
                }
            }

            return isAnyProductInvetoryZero
        }

        const togglePaySalesModal = (status) => {
            const isAnyProductInvetoryZero = validateItems()
            if (allowZeroStockLevel.value && isAnyProductInvetoryZero) {
                store.dispatch('Alert/setAlert', {
                    message: 'Item is not sufficient in inventory, please contact Admin or Inventory Manager to update stock',
                    success: false,
                })
                return false
            }

            store.dispatch('Pos/showPaySalesModal', status)
            store.dispatch('Pos/getAmountRemaining')
        }

        const onRetrieveSales = (data) => {
            updatePosData()
            state.pos.id = data._id
            state.pos.reference = data.reference
            state.pos.customer.id = data?.customer?.id || ''
            state.pos.customer.name = data?.customer?.name || ''
            state.pos.customer.email = data?.customer?.email || ''
            state.pos.customer.phone = data?.customer?.phone || ''
            state.pos.customer.address = data?.customer?.address || ''
            state.pos.customer.city = data?.customer?.city || ''
            state.pos.customer.state = data?.customer?.state || ''
            state.pos.customer.country = data?.customer?.country || ''
            state.pos.customer.vat_number = data?.customer?.vat_number || ''
            state.pos.customer.tax_number = data?.customer?.tax_number || ''
            state.pos.customer.website = data?.customer?.website || ''

            state.pos.notes = data.notes
            state.entity = data?.customer?.name || ''

            state.showDiscount = state.pos?.discount !== 0 && state.pos?.discount !== ''
            state.showTax = state.pos?.tax !== 0 && state.pos?.tax !== ''
            state.showNote = state.pos?.notes !== ''
            state.canRetrieveSale = false

            setEntityInitials(data?.customer?.name || '')
        }

        const toggleCreateProduct = (status, data) => {
            store.dispatch('Inventory/showCreateProduct', { show: status, cat: 'PRODUCT' })
            if (status && data) {
                store.dispatch('Inventory/showIncomingProductName', data)
            }
            // store.dispatch('Inventory/resetProductEntryBySearch', [])
        }

        watch(
            () => customer.value?.id,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    updatePosData()
                }
            }
        )

        watch(
            () => amount.value,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    state.pos.totalMoneySaved = calculateTotalMoneySaved(state.pos)
                }
            }
        )

        watch(
            () => state.showTax,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    const tax = newVal ? accountTaxDefault.value : 0
                    store.dispatch('Pos/taxChange', tax)
                    state.pos.tax = tax
                    localStorage.setItem(`pos_tax_${userId?.value}`, state.pos.tax)
                }
            },
            { immediate: true }
        )

        onMounted(() => {
            const parkedReference = route.query?.parked
            if (parkedReference) {
                store
                    .dispatch('Pos/retrieveSale', parkedReference)
                    .then((data) => {
                        onRetrieveSales(data)
                    })
                    .finally(() => {
                        router.replace({ query: { parked: null } })
                    })
            }

            store.dispatch('Pos/getProducts')
            store.dispatch('Pos/getActiveRegister')
            store.dispatch('Tag/getTags').then((data) => {
                if (data.status) {
                    state.myTags = data?.data || []
                }
            })
            store.dispatch('Settings/getAccountSettings').then((data) => {
                if (data.status) {
                    state.allowZeroStockLevel = data?.data?.allowZeroStockLevel || false
                }
            })

            const storedPosItem = localStorage.getItem(`pos_item_${userId?.value}`)
            const storedPosCustomer = localStorage.getItem(`pos_customer_${userId?.value}`)
            const storedPosDiscount = localStorage.getItem(`pos_discount_${userId?.value}`)
            const storedPosDiscountType = localStorage.getItem(`pos_discount_type_${userId?.value}`)
            const storedPosTax = localStorage.getItem(`pos_tax_${userId?.value}`)
            if (storedPosItem) {
                state.pos.items = JSON.parse(storedPosItem)
                store.dispatch('Pos/setPosItem', state.pos.items)
            }
            if (storedPosCustomer) {
                state.pos.customer = JSON.parse(storedPosCustomer)
                store.dispatch('Pos/setCustomer', state.pos.customer)
            }
            if (storedPosDiscount) {
                state.pos.discount = storedPosDiscount
                store.dispatch('Pos/discountChange', state.pos.discount)
                state.showDiscount = true
            }
            if (storedPosDiscountType) {
                state.pos.discountType = storedPosDiscountType
                store.dispatch('Pos/discountTypeChange', state.pos.discountType)
            }
            if (storedPosTax && storedPosTax != 0) {
                state.pos.tax = storedPosTax
                store.dispatch('Pos/taxChange', state.pos.tax)
                state.showTax = true
            }
        })

        return {
            state,
            productItems,
            posItems,
            customer,
            totalItems,
            subtotal,
            tax,
            discount,
            discountType,
            amount,
            onProductCreated,
            addPosItem,
            onItemChange,
            onItemDiscountChange,
            onItemPriceChange,
            onTaxChange,
            onDiscountChange,
            onDiscountTypeChange,
            removePosItem,
            toggleRetrieveSalesModal,
            togglePaySalesModal,
            updatePosData,
            onRetrieveSales,
            handleProductSearchOnChange,
            posItemsRef,
            addFirstItem,
            createSale,
            formatAmount,
            titleCase,
            updateGlAccounts,
            activeRegister,
            canAccessPos,
            toggleCreateProduct,
            parkedSales,
            accountTaxDefault,
            role,
            rolePermissions,
            toggleProductVariantsModal,
        }
    },

    methods: {
        completeSale(status) {
            this.$posReceipt('receiptContainer')
            this.createSale(status)
        },
        viewReceipt() {
            this.$posReceipt('receiptContainer')
        },
    },
}
</script>
<style scoped>
.searchContainer2 {
    overflow: unset;
}
.org-img-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(215, 222, 250);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    font-size: 25px;
    color: rgb(19, 44, 140);
    margin-right: 5px;
}
.stickyNew {
    position: sticky;
    top: 0;
    z-index: 53;
}
</style>
