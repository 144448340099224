<template>
    <!-- form modal -->

    <div
        v-if="showPaySaleModal"
        tabindex="0"
        @keydown.esc="closeModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showPaySaleModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-70-l w-90 pv2 center box-border" style="background: white" ref="paySaleRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Pay Sale</div>
                <div>
                    <router-link to="#">
                        <img src="../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <div class="pa3">
                <div class="flex flex-wrap items-center justify-between">
                    <div class="w-50-l w-100 scrollmenu" style="height: 400px;">
                        <div v-for="(item, index) in posItems" class="flex justify-between pa3 box-border-bottom" :key="index">
                            <div class="flex">
                                <div>{{ item.quantity }}</div>
                                <div class="pl3">
                                    <div>
                                        <div class="font-w1">{{ titleCase(item.name) }}</div>
                                        <div>
                                            <span class="pl2" v-if="item?.discount > 0 && item?.discount !== ''">
                                                <span style="text-decoration:line-through">{{ formatAmount(item?.oldPrice, $store.state.Settings?.currency) }}</span> {{ ' ' }}
                                            </span>
                                            <span>{{
                                                formatAmount(
                                                    item.price,
                                                    $store.state.Settings?.currency
                                                )
                                            }}</span>
                                        </div>
                                    </div>
                                    <small v-if="item?.note">{{ item.note }}</small>
                                </div>
                            </div>
                            <div style="color: #132C8C;">{{ formatAmount(item.total, $store.state.Settings?.currency) }}</div>
                        </div>
                        <div class="flex justify-between pa3 box-border-bottom">
                            <div class="pl4 b">SUBTOTAL</div>
                            <div>{{ formatAmount(subtotal, $store.state.Settings?.currency) }}</div>
                        </div>
                        <div v-if="discount !== 0 && discount !== ''" class="flex justify-between pa3 box-border-bottom">
                            <div class="pl4 b">
                                DISCOUNT <span v-if="discountType === 'percent'">({{ discount }}%)</span>
                            </div>
                            <div v-if="discountType === 'percent'">
                                ({{ formatAmount((discount / 100) * subtotal, $store.state.Settings?.currency) }})
                            </div>
                            <div v-else>({{ formatAmount(discount, $store.state.Settings?.currency) }})</div>
                        </div>
                        <div v-if="tax !== 0 && tax !== ''" class="flex justify-between pa3 box-border-bottom">
                            <div class="pl4 b">
                                TAX <span>({{ tax }}%)</span>
                            </div>
                            <div v-if="discountType === 'percent'">
                                {{
                                    formatAmount(
                                        (tax / 100) * (subtotal - (discount / 100) * subtotal),
                                        $store.state.Settings?.currency
                                    )
                                }}
                            </div>
                            <div v-else>
                                {{ formatAmount((tax / 100) * (subtotal - discount), $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div
                            v-for="(payment, index) in glAccounts"
                            class="flex justify-between pa3 box-border-bottom"
                            :key="index"
                        >
                            <a href="" @click.prevent="removePaymentOption(index, payment.glAmount)">
                                <img src="https://img.icons8.com/dotty/22/000000/delete-sign.png" alt="" style="width:60%;" />
                            </a>
                            <div class="pl4 b">{{ payment.glAccount }}</div>
                            <div>{{ formatAmount(payment.glAmount, $store.state.Settings?.currency) }}</div>
                        </div>
                        <div v-if="amountRemaining < 0" class="flex justify-between pa3 box-border-bottom">
                            <div>&nbsp;</div>
                            <div class="pl4 b">Customer Change</div>
                            <div class="text-danger">
                                {{ formatAmount(amountCollected - amount, $store.state.Settings?.currency) }}
                            </div>
                        </div>
                        <div class="flex justify-between pa3 box-border-bottom">
                            <div class="pl4 f3 b">Sales Total</div>
                            <div class="b f3">{{ formatAmount(amount, $store.state.Settings?.currency) }}</div>
                        </div>
                    </div>
                    <div class="w-50-l w-100" style="background: #F6F8FE;">
                        <div v-if="amountRemaining > 0" class="flex flex-wrap justify-between items-center pa3">
                            <div class="f3 b w-40-l w-100">Amount to Pay</div>
                            <div class="w-50-l w-100 pv2">
                                <input
                                    type="number"
                                    :placeholder="formatAmount(amountToPay, $store.state.Settings?.currency)"
                                    class="tr mb2 pa1 box-border w-100"
                                    @input="element => setAmountToPay(element.target.value)"
                                    v-model="amountToPay"
                                />
                                <div class="f6">Edit to make partial payment</div>
                            </div>
                        </div>
                        <div v-else-if="amountRemaining < 0" class="flex flex-wrap justify-between items-center pa3">
                            <div class="f2 b w-100-l w-100 tc">
                                Give
                                <span class="f2 b text-danger">{{
                                    formatAmount(amountCollected - amount, $store.state.Settings?.currency)
                                }}</span>
                                Change
                            </div>
                        </div>
                        <div v-else class="flex flex-wrap justify-between items-center pa3">
                            <div class="f2 b w-100-l w-100 tc">Payment Received</div>
                        </div>
                        <div class="pa3">
                            <div class="flex justify-between items-center pa3 box-border1">
                                <pos-customer @onUpdatePosData="updatePosData" />
                            </div>
                            <div v-if="amountRemaining > 0" class="flex justify-between items-center pa3 box-border1">
                                <div class="pl5-l pl0">
                                    <div class="pb1">Amount Balance</div>
                                    <div style="color: #132C8C;">
                                        {{ formatAmount(amountRemaining, $store.state.Settings?.currency) }}
                                    </div>
                                </div>
                                <!-- <div>
                                    <div class="pb1">Store Credit</div>
                                    <div style="color: #132C8C;">$0.00</div>
                                </div> -->
                            </div>
                        </div>
                        <div v-if="amountRemaining > 0" class="flex flex-wrap justify-start pa3">
                            <button class="w-30-l w-100 mb3 mb1-l mr1" @click="cashPayment">Cash</button>
                            <button
                                v-for="(bank, index) in bankAccounts"
                                :key="index"
                                class="btn4 w-30-l w-100 mb3 mb1-l mr1"
                                @click="bankPayment(bank)"
                            >
                                {{ bank?.aliasName || bank?.bankName }}
                            </button>
                        </div>
                        <div v-else class="flex flex-wrap justify-start pa3">
                            <button
                                :key="state.processingKey"
                                class="w-100-l w-100 mb3 mb1-l mr1"
                                :disabled="state.isProcessing"
                                @click.once="completeSale"
                            >
                                Complete Sale
                            </button>
                            <!-- <div class="tc">
                                <a href="#" @click.prevent="viewReceipt" class="tc">View Receipt</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { formatAmount, titleCase } from '@/utils/lib'
import PosCustomer from './PosCustomer.vue'

export default {
    name: 'PosPayModal',
    components: { PosCustomer },
    props: {
        closeModal: Function
    },

    setup(props, { emit }) {
        const paySaleRef = ref(null)
        const store = useStore()
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const posItems = computed(() => store.state?.Pos?.items)
        const customer = computed(() => store.state?.Pos?.customer)
        const entityInitials = computed(() => store.state.Entity?.entityInitials)
        const subtotal = computed(() => store.state?.Pos?.subtotal)
        const tax = computed(() => store.state?.Pos?.tax)
        const discount = computed(() => store.state?.Pos?.discount)
        const discountType = computed(() => store.state?.Pos?.discountType)
        const amount = computed(() => store.state?.Pos?.amount)
        const glAccounts = computed(() => store.state?.Pos?.glAccounts)
        const amountCollected = computed(() => store.state?.Pos?.amountCollected)
        const amountRemaining = computed(() => store.state?.Pos?.amountRemaining)
        const amountToPay = computed(() => store.state?.Pos?.amountToPay)
        const showPaySaleModal = computed(() => store.state?.Pos?.showPaySaleModal)

        const initialState = {
            amountToPay: 0,
            isProcessing: false,
            processingKey: 0,
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeRetrieveSaleModal = () => {
            props.closeModal()
        }

        const updatePosData = () => {
            emit('onUpdatePosData')
        }

        const handleOnClickOutsideModal = e => {
            // if (paySaleRef?.value && !paySaleRef?.value.contains(e.target)) {
            //     closeRetrieveSaleModal()
            // }
        }

        const handleOnPayment = async data => {
            // if (amountRemaining.value < 0) {
            //     alert(amountRemaining.value)
            // }
            emit('onPayment', data)
            await store.dispatch('Pos/getAmountRemaining', data?.glAccount)
        }

        const cashPayment = () => {
            const data = {
                glAccount: 'Cash',
                glAmount: state.amountToPay
            }
            handleOnPayment(data)
        }

        const bankPayment = bank => {
            const data = {
                glAccount: bank?.aliasName || bank?.bankName,
                glAmount: state.amountToPay
            }
            handleOnPayment(data)
        }

        const setAmountToPay = value => {
            state.amountToPay = value
        }

        const completeSale = () => {
            state.isProcessing = true
            emit('onCompleteSale', 'completed')
            setTimeout(() => {
                state.isProcessing = false
                state.processingKey++
            }, 5000)
        }

        const viewReceipt = () => {
            emit('onViewReceipt')
        }

        const removePaymentOption = (index, amount) => {
            store.dispatch('Pos/removePaymentOption', { index, amount })
        }

        watch(
            () => amountRemaining.value,
            (currValue, prevValue) => {
                if (currValue !== prevValue) {
                    state.amountToPay = currValue
                }
            }
        )

        onMounted(() => {
            store.dispatch('Settings/getBankAccounts')
            store.dispatch('Pos/getAmountRemaining')
        })

        return {
            state,
            bankAccounts,
            posItems,
            customer,
            entityInitials,
            subtotal,
            tax,
            discount,
            discountType,
            amount,
            amountCollected,
            amountRemaining,
            amountToPay,
            glAccounts,
            setAmountToPay,
            formatAmount,
            removePaymentOption,
            titleCase,
            completeSale,
            viewReceipt,
            showPaySaleModal,
            paySaleRef,
            handleOnClickOutsideModal,
            updatePosData,
            cashPayment,
            bankPayment
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
